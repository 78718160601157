/* Glassmorphism Theme for FlowMoney
   Modern flat design with glassmorphism accents */

:root {
  --primary-color: #2b3041;
  --accent-color: #fd8c22;
  --secondary-color: #4a9dff;
  --positive-color: #4caf50;
  --negative-color: #f44336;
  --background-color: #f8f9fa;
  --text-color: #333333;
  --text-light: #ffffff;
  --glass-background: rgba(255, 255, 255, 0.25);
  --glass-border: rgba(255, 255, 255, 0.18);
  --glass-shadow: rgba(31, 38, 135, 0.15);
  --glass-navbar: rgba(43, 48, 65, 0.75);
  --card-radius: 10px;
  --transition-speed: 0.3s;
}

/* Base styles */
body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: "Inter", "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

/* Glassmorphism Card */
.glass-card {
  background: var(--glass-background);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: var(--card-radius);
  border: 1px solid var(--glass-border);
  box-shadow: 0 8px 32px 0 var(--glass-shadow);
  padding: 20px;
  transition: all var(--transition-speed) ease;
}

.glass-card:hover {
  box-shadow: 0 12px 48px 0 var(--glass-shadow);
  transform: translateY(-2px);
}

/* Glass Navbar */
.glass-navbar {
  background: var(--glass-navbar);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-right: 1px solid var(--glass-border);
  color: var(--text-light);
  transition: all var(--transition-speed) ease;
}

/* Buttons */
.glass-button {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid var(--glass-border);
  border-radius: 8px;
  color: var(--primary-color);
  cursor: pointer;
  font-weight: 500;
  padding: 10px 20px;
  transition: all var(--transition-speed) ease;
}

.glass-button:hover {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.glass-button.accent {
  background: var(--accent-color);
  color: var(--text-light);
}

.glass-button.accent:hover {
  background: rgba(253, 140, 34, 0.85);
  box-shadow: 0 8px 16px rgba(253, 140, 34, 0.3);
}

/* Input fields */
.glass-input {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid var(--glass-border);
  border-radius: 8px;
  color: var(--text-color);
  padding: 10px 15px;
  transition: all var(--transition-speed) ease;
}

.glass-input:focus {
  border-color: var(--accent-color);
  box-shadow: 0 0 0 2px rgba(253, 140, 34, 0.2);
  outline: none;
}

/* Tables */
.glass-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.glass-table th {
  background: rgba(43, 48, 65, 0.1);
  color: var(--primary-color);
  font-weight: 600;
  padding: 12px 15px;
  text-align: left;
}

.glass-table tr:nth-child(even) {
  background: rgba(255, 255, 255, 0.05);
}

.glass-table td {
  padding: 12px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

/* Status indicators */
.status-positive {
  color: var(--positive-color);
}

.status-negative {
  color: var(--negative-color);
}

/* Card variations */
.glass-card.positive {
  border-left: 4px solid var(--positive-color);
}

.glass-card.negative {
  border-left: 4px solid var(--negative-color);
}

.glass-card.neutral {
  border-left: 4px solid var(--secondary-color);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn var(--transition-speed) ease forwards;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .glass-card {
    padding: 15px;
  }

  .glass-button {
    padding: 8px 16px;
  }
}
