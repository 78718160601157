.help-container {
  margin-top: 30px;
  margin-left: 23px;
  width: calc(100% - 23px);
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 100px);
}

.help-paper {
  width: 100%;
  max-width: 900px;
  padding: 40px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.help-paper:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
  transform: translateY(-5px);
}

.help-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.help-icon {
  font-size: 48px !important;
  color: var(--accent-color);
  margin-right: 20px;
}

.help-title {
  color: var(--primary-color);
  font-weight: 600;
  margin-bottom: 5px;
}

.help-subtitle {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}

.faq-container {
  margin-top: 20px;
}

/* Stile personalizzato per il componente FAQ */
.faq-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 16px 0;
}

.faq-row-wrapper {
  padding: 8px 0;
  transition: all 0.3s ease;
}

.faq-row-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.row-title {
  font-weight: 500 !important;
}

.row-title-text {
  padding-right: 40px;
}

.row-content-text {
  padding-top: 10px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .help-container {
    margin-left: 0;
    width: 100%;
    padding: 15px;
  }

  .help-paper {
    padding: 20px;
  }

  .help-icon {
    font-size: 36px !important;
    margin-right: 15px;
  }

  .help-title {
    font-size: 24px;
  }

  .help-subtitle {
    font-size: 12px;
  }
}
