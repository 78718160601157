/* Aggiungo questi stili all'inizio del file per dare loro priorità */
/* Fix per il date picker */
.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
  width: auto;
}

/* Stili critici per il date picker - questi devono avere la massima priorità */
.react-datepicker-popper {
  z-index: 10000 !important;
  position: absolute !important;
}

/* Forza il calendario a stare sopra tutto */
.react-datepicker {
  font-family: inherit !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15) !important;
  z-index: 10000 !important;
}

/* Assicura che il portale del date picker sia sopra tutto */
.react-datepicker-portal {
  z-index: 10000 !important;
}

/* Forza il triangolo di posizionamento a essere visibile */
.react-datepicker__triangle {
  position: absolute !important;
  z-index: 10000 !important;
}

/* Stili per il resto del date picker */
.react-datepicker__header {
  background-color: var(--primary-color) !important;
  color: white !important;
  border-bottom: none !important;
  z-index: 10000 !important;
}

.react-datepicker__current-month,
.react-datepicker__day-name {
  color: white !important;
}

.react-datepicker__day--selected {
  background-color: var(--accent-color) !important;
}

.react-datepicker__day:hover {
  background-color: rgba(var(--accent-color-rgb), 0.2) !important;
}

/* Assicura che il wrapper del date picker abbia un contesto di stacking corretto */
.react-datepicker-wrapper {
  position: relative;
  z-index: 1;
}

.report {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--background-color);
  min-height: 100vh;
  padding-top: 20px;
  padding-left: 100px;
  padding-right: 20px;
  overflow-x: hidden;
  max-width: 100vw;
  box-sizing: border-box;
}

/* Modern container styles */
.report-container-modern {
  margin-bottom: 30px;
  position: static !important; /* Previene che il container interferisca con il date picker */
  max-width: 100% !important;
  overflow-x: hidden !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  box-sizing: border-box;
}

/* Stili per il container MUI */
.MuiContainer-root {
  max-width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  overflow-x: hidden !important;
  box-sizing: border-box;
}

/* Assicura che il Paper non causi overflow */
.MuiPaper-root {
  max-width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

/* Header section */
.report-header {
  margin-bottom: 24px;
  position: static !important;
  width: calc(100% - 100px);
  margin-left: 10px;
}

.report-title-section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.report-icon {
  font-size: 36px;
  color: var(--accent-color);
  margin-right: 16px;
}

.report-title {
  font-weight: 600;
  color: var(--text-color);
}

/* Controls section */
.report-controls {
  padding: 20px;
  margin-bottom: 24px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  position: static !important; /* Cambiato da relative a static */
}

.date-picker-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  position: static !important; /* Cambiato da relative a static */
}

.date-picker-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: static !important; /* Cambiato da relative a static */
}

.date-input-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  position: static !important; /* Cambiato da relative a static */
}

.date-input-modern {
  width: 150px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  font-size: 16px;
  text-align: center;
  padding: 0 12px;
  transition: all 0.2s ease;
  position: relative;
}

.date-input-modern:focus {
  border-color: var(--accent-color);
  outline: none;
  box-shadow: 0 0 0 2px rgba(var(--accent-color-rgb), 0.2);
}

.date-icon {
  color: var(--accent-color);
  font-size: 24px;
}

.search-button {
  background-color: var(--accent-color) !important;
  color: white !important;
  border-radius: 8px !important;
  padding: 8px 16px !important;
  text-transform: none !important;
  font-weight: 500 !important;
  box-shadow: 0 4px 12px rgba(var(--accent-color-rgb), 0.3) !important;
  transition: all 0.2s ease !important;
}

.search-button:hover {
  background-color: var(--accent-color-dark) !important;
  box-shadow: 0 6px 16px rgba(var(--accent-color-rgb), 0.4) !important;
}

.export-button-container {
  display: flex;
  justify-content: flex-end;
}

.link-pdf-modern {
  text-decoration: none;
}

.export-button {
  background-color: var(--primary-color) !important;
  color: white !important;
  border-radius: 8px !important;
  padding: 8px 16px !important;
  text-transform: none !important;
  font-weight: 500 !important;
  box-shadow: 0 4px 12px rgba(var(--primary-color-rgb), 0.3) !important;
  transition: all 0.2s ease !important;
}

.export-button:hover {
  background-color: var(--primary-color-dark) !important;
  box-shadow: 0 6px 16px rgba(var(--primary-color-rgb), 0.4) !important;
}

/* Summary section */
.summary-section {
  padding: 24px;
  margin-bottom: 24px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

/* Voci Cassa section */
.voci-cassa-section {
  padding: 24px 24px 24px 24px;
  margin-bottom: 24px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  width: calc(100% - 90px);
  position: relative;
  max-width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  margin-left: 10px;
}

/* Indicatore di scroll */
.voci-cassa-section::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 40px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  pointer-events: none;
  z-index: 1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.voci-cassa-inner-container {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.voci-cassa-container {
  display: flex;
  overflow-x: auto;
  padding: 8px 0;
  gap: 16px;
  scrollbar-width: thin;
  scrollbar-color: var(--accent-color) #f0f0f0;
  width: 100%;
  max-width: 100%;
  -webkit-overflow-scrolling: touch; /* Per uno scrolling fluido su iOS */
  padding-bottom: 12px; /* Spazio per la scrollbar */
  margin-bottom: -8px; /* Compensa il padding-bottom */
  flex-wrap: nowrap; /* Impedisce il wrapping delle card */
  position: relative;
  box-sizing: border-box;
}

.voci-cassa-container::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.voci-cassa-container::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 10px;
}

.voci-cassa-container::-webkit-scrollbar-thumb {
  background-color: var(--accent-color);
  border-radius: 10px;
}

.voce-cassa-item {
  display: flex;
  align-items: center;
  min-width: 170px;
  width: 170px; /* Larghezza fissa più contenuta */
  padding: 12px;
  background-color: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
  flex-shrink: 0;
  margin-bottom: 8px;
  overflow: hidden; /* Previene overflow del contenuto */
  box-sizing: border-box;
}

.voce-cassa-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.voce-cassa-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  flex-shrink: 0;
}

.voce-cassa-icon {
  font-size: 20px !important;
}

.voce-cassa-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  width: calc(100% - 52px); /* 40px per l'icona + 12px di margine */
}

.voce-cassa-title {
  color: var(--text-secondary);
  font-weight: 500 !important;
  margin-bottom: 4px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* Limita la larghezza del titolo */
  font-size: 0.875rem !important;
}

.voce-cassa-value {
  font-weight: 600 !important;
  white-space: nowrap; /* Previene il wrapping del valore */
  font-size: 1rem !important;
}

/* Stili per le nuove card di riepilogo semplici */
.simple-summary-card {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  height: 100%;
  transition: all 0.2s ease;
}

.simple-summary-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.simple-summary-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #e8f0fe;
  border-radius: 50%;
  margin-right: 16px;
  flex-shrink: 0;
}

.simple-summary-icon {
  font-size: 24px !important;
  color: var(--accent-color);
}

.simple-summary-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.simple-summary-title {
  color: var(--text-secondary);
  font-weight: 500 !important;
  margin-bottom: 4px !important;
}

.simple-summary-value {
  font-weight: 600 !important;
  color: var(--accent-color);
}

.simple-summary-value.amount {
  color: var(--accent-color);
}

.simple-summary-value.expenses {
  color: #e53935; /* Rosso per le spese */
}

/* Stili per le intestazioni delle sezioni */
.section-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.section-icon {
  font-size: 28px !important;
  color: var(--accent-color);
  margin-right: 12px;
}

.section-title {
  font-weight: 600;
  color: var(--text-color);
}

/* Charts section */
.charts-section {
  padding: 24px;
  margin-bottom: 24px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  width: calc(100% - 90px);
  margin-left: 10px;
}

.charts-container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
}

/* Responsive adjustments */
@media (max-width: 960px) {
  .report {
    padding-left: 80px;
    padding-right: 16px;
  }

  .export-button-container {
    justify-content: flex-start;
    margin-top: 16px;
  }

  .date-picker-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .date-picker-item {
    width: 100%;
  }

  .simple-summary-card {
    margin-bottom: 16px;
  }

  .voce-cassa-item {
    min-width: 160px;
    width: 160px;
    padding: 10px;
  }

  .voce-cassa-icon-container {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }

  .voce-cassa-icon {
    font-size: 18px !important;
  }

  .voce-cassa-content {
    width: calc(100% - 46px); /* 36px per l'icona + 10px di margine */
  }
}

@media (max-width: 600px) {
  .report {
    padding-left: 16px;
  }

  .date-input-modern {
    width: 120px;
  }

  .simple-summary-card {
    margin-bottom: 16px;
  }

  .voce-cassa-item {
    min-width: 150px;
    width: 150px;
    padding: 8px;
  }

  .voce-cassa-icon-container {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }

  .voce-cassa-icon {
    font-size: 16px !important;
  }

  .voce-cassa-content {
    width: calc(100% - 40px); /* 32px per l'icona + 8px di margine */
  }

  .voce-cassa-title {
    font-size: 0.8rem !important;
  }

  .voce-cassa-value {
    font-size: 0.9rem !important;
  }

  .voci-cassa-section {
    padding: 16px;
  }

  .section-header {
    margin-bottom: 12px;
  }
}

/* Legacy styles kept for backward compatibility */
.report-container {
  padding-top: 0.5vw;
  margin-left: 4vw;
  display: flex;
  flex-direction: column;
  width: 70vw;
  overflow-x: scroll;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  height: 90%;
  border-radius: 5px;
}

.report-head-sub-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 1vw;
  border-bottom: 3px solid var(--secodary-color);
  padding-bottom: 0.5vw;
  margin-left: auto;
}

.head-voce-data {
  width: 10vw;
  height: 2.5vw;
  background-color: #2b3041;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  margin-right: 1vw;
}

.report-head {
  display: flex;
  flex-direction: row;
}

.report-footer-sub-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 1vw;
  border-top: 3px solid var(--secodary-color);
  padding-top: 0.5vw;
  margin-left: auto;
}

.data-stats {
  margin-left: 2vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.p-div {
  width: 20vw;
  height: 2.5vw;
  margin-top: 0.5vw;
  background-color: var(--primary-color);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.pdf-div {
  color: white;
  width: 20vw;
  margin-left: 20px;
  background-color: #2b3041;
  border-color: #2b3041;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 3px solid #2b3041;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  transition: ease background-color 250ms;
  font-size: 25px;
  margin-top: 6px;
}

.pdf-div:hover {
  background-color: var(--primary-color);
  border: 3px solid var(--primary-color);
}

.pdf-div svg {
  font-size: 20px;
  color: #fff;
  margin-right: 1.5vw;
}

.link-pdf {
  text-decoration: none;
}

.pdf-page {
  margin-left: 2.8vw;
}

.ip-style {
  color: #fff !important;
  background-color: var(--secodary-color) !important;
}

.tot-netto {
  color: white;
  height: 4.5vw;
  width: 20vw;
  margin-top: 2.7vw;
  background-color: #2b3041;
  margin-bottom: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 2vw;
}

.ipt-report {
  align-items: center !important;
  flex-direction: row !important;
  display: flex !important;
  margin-top: 10px !important;
}

.row_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  font-size: 35px;
  color: white;
}

.row_date {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.row_date p {
  font-size: 18px;
  color: var(--secondary-color);
  margin-bottom: 0;
  margin-right: 5px;
}

.input_date {
  width: 150px;
  height: 33px;
  border-radius: 3px;
  border: 1px solid var(--secondary-color);
  font-size: 18px;
  text-align: center;
}

.stats_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 150px;
  width: 100%;
  padding-left: 10px;
  margin-top: 10px;
  overflow-y: auto;
}

.stats_container_totali {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: max-content;
  width: 99.5%;
  padding-left: 10px;
  padding: 10px;
  margin-left: 10px;
  overflow-y: auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: white;
}

.contaienr_totale {
  padding-top: 10px;
  height: 80%;
  width: 100%;
}

.contaienr_stats_detail {
  height: 80%;
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.head_tot {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 30px;
  height: 20%;
  width: 100%;
  font-size: 30px;
  font-weight: bold;
}

.col_data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 30px;
  height: 80%;
  width: 50%;
  font-size: 20px;
}

.row_data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 15px;
}

.row_data p {
  margin-right: 10px;
  margin-bottom: 7px;
  width: 180px;
  font-size: 17px;
}

.row_body_stats {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
