.btn-add-credito {
  border: none;
  background: none;
  font-size: 2vw;
  /* height: 3vw; */
  margin-top: 1vw;
  width: 3vw;
  margin-bottom: 1vw;
  color: #0a0101;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.btn-add-credito:hover {
  color: var(--color-save-ico);
  font-size: 2.1vw;
  border: none;
}
.btn-add-credito-big {
  border: none;
  background: none;
  font-size: 9vw;
  /* height: 3vw; */
  width: 9vw;
  margin-bottom: 1vw;
  color: #0a0101;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-add-credito-big:hover {
  color: var(--color-save-ico);
  font-size: 9.2vw;
  border: none;
}

@media (max-width: 700px) {
  .btn-add-credito {
    font-size: 200%;
    width: 8vw;
  }
  .btn-add-credito:hover {
    color: var(--color-save-ico);
    font-size: 200%;
    width: 8vw;
    border: none;
  }
}
