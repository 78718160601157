.movimenti-cassa {
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  display: flex;
  flex-direction: column;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  width: 25%;
  min-width: 280px;
  height: calc(100vh - 40px);
}

.movimenti-cassa .div_ {
  height: auto;
  padding: 12px;
  border-radius: 8px;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.head-movimenti-cassa {
  width: 9vw;
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  color: var(--primary-color);
}

.head-movimenti {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
}

.body-mov-cassa {
  overflow-y: auto;
  border-radius: 8px;
  flex: 1;
  padding-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  max-height: calc(100vh - 160px);
}

.footer-actions {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.ico-head-cs {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.6vw;
  padding-left: 0.7vw;
  padding-right: 1vw;
  color: var(--primary-color);
  transition: all 0.3s ease;
}

.ico-head-cs:hover {
  color: var(--accent-color);
  transform: scale(1.05);
}

/* Stile compatto per l'header */
.compact-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.compact-header-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: rgba(43, 48, 65, 0.05);
}

.compact-header-content {
  display: flex;
  flex-direction: column;
}

.compact-header-title {
  font-size: 12px !important;
  color: #666 !important;
  line-height: 1.2 !important;
}

.compact-header-value {
  font-size: 15px !important;
  line-height: 1.2 !important;
}

@media (max-width: 768px) {
  .movimenti-cassa {
    width: 90%;
    margin: 10px auto;
    min-width: auto;
  }

  .body-mov-cassa {
    max-height: 40vh;
  }

  .compact-header-title {
    font-size: 11px !important;
  }

  .compact-header-value {
    font-size: 14px !important;
  }
}

.header-with-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 15px 8px;
  background-color: transparent;
}

.header-left {
  flex: 1;
}

.header-action-button {
  background-color: var(--accent-color) !important;
  color: white !important;
  width: 36px;
  height: 36px;
  border-radius: 50% !important;
  box-shadow: 0 2px 5px rgba(253, 140, 34, 0.3) !important;
  transition: all 0.3s ease !important;
}

.header-action-button:hover {
  background-color: rgba(253, 140, 34, 0.9) !important;
  box-shadow: 0 4px 10px rgba(253, 140, 34, 0.4) !important;
  transform: translateY(-2px);
}
