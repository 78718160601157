.navbar {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--background-color);
}

.menu-bars {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  text-decoration: none;
  width: 100%;
  transition: all 0.3s ease;
}

.nav-menu {
  background-color: var(--glass-navbar);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -210px;
  transition: all 0.3s ease;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
  z-index: 10;
  border-right: 1px solid var(--glass-border);
}

.nav-menu.active {
  left: 0;
  transition: all 0.3s ease;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px;
  list-style: none;
  height: 50px;
  margin-bottom: 4px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.nav-text a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
}

.nav-text.active a {
  background-color: rgba(253, 140, 34, 0.2);
  color: var(--accent-color);
}

.nav-menu-items {
  width: 100%;
  padding: 0;
  margin: 0;
}

/* Glassmorphism effects for the sidebar */
.glass-navbar .MuiPaper-root {
  background: var(--glass-navbar) !important;
  backdrop-filter: blur(12px) !important;
  -webkit-backdrop-filter: blur(12px) !important;
  border-right: 1px solid var(--glass-border) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15) !important;
}

/* Miglioramento del centramento delle icone */
.glass-navbar .MuiListItemIcon-root {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.glass-navbar .MuiListItemIcon-root svg {
  font-size: 24px !important;
}

.glass-navbar .MuiListItemButton-root {
  transition: all 0.3s ease !important;
}

.glass-navbar .MuiListItemButton-root:hover {
  transform: translateY(-2px) !important;
}

.glass-navbar .MuiDivider-root {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

/* Rimuove lo spazio tra la sidebar e il contenuto principale */
.MuiDrawer-root + div {
  padding-left: 0 !important;
  padding: 0;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .glass-navbar {
    z-index: 1300;
  }

  .glass-navbar .MuiDrawer-paper {
    width: 60px !important;
  }

  .glass-navbar .MuiDrawer-paper.MuiDrawer-paperOpen {
    width: 240px !important;
  }
}

/* Stile moderno per la sidebar */
.modern-sidebar .MuiPaper-root {
  background: var(--secodary-color) !important;
  border-right: none !important;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15) !important;
}

/* Miglioramento del centramento delle icone */
.modern-sidebar .MuiListItemIcon-root {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.modern-sidebar .MuiListItemIcon-root svg {
  font-size: 22px !important;
}

.modern-sidebar .MuiListItemButton-root {
  transition: all 0.3s ease !important;
}

.modern-sidebar .MuiListItemButton-root:hover {
  transform: translateY(-2px) !important;
}

.modern-sidebar .MuiDivider-root {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

/* Rimuove lo spazio tra la sidebar e il contenuto principale */
.MuiDrawer-root + div {
  padding-left: 0 !important;
}

/* Stile per il pulsante attivo */
.modern-sidebar .Mui-selected {
  background-color: var(--accent-color) !important;
  color: white !important;
  box-shadow: 0 4px 12px rgba(253, 140, 34, 0.4) !important;
}

.modern-sidebar .Mui-selected .MuiListItemIcon-root {
  color: white !important;
}

.modern-sidebar .Mui-selected .MuiListItemText-primary {
  color: white !important;
  font-weight: 600 !important;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .modern-sidebar {
    z-index: 1300;
  }

  .modern-sidebar .MuiDrawer-paper {
    width: 60px !important;
  }

  .modern-sidebar .MuiDrawer-paper.MuiDrawer-paperOpen {
    width: 240px !important;
  }
}
