:root {
  --background-color: #f1f3f9;
  --input-color: #fff;
  --input-border: #4c79d8;
  --input-background: rgba(255, 255, 255, 0.1);
  --input-placeholder: #CBD1DC;
  --color-delete-ico: #ec6a5e;
  --color-save-ico: #61c554;
  --input-border-focus: #fd8c22;
  --color-gray-ico: #45464a;
  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background: rgba(76, 121, 216, 0.8);

  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: rgba(253, 140, 34, 0.8);

  --primary-color: #4c79d8;
  --secodary-color: #2b3041;
  --accent-color: #fd8c22;

  --glass-background: rgba(255, 255, 255, 0.25);
  --glass-border: rgba(255, 255, 255, 0.18);
  --glass-shadow: rgba(31, 38, 135, 0.15);

  --gray-light: rgb(147, 147, 147);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.voce-cassa-card {
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  transition: all 0.3s ease;
  padding: 0;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  border: none;
  height: 42px;
}

.voce-cassa-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.12);
}

/* Nome della voce */
.nome {
  background-color: var(--secodary-color);
  height: 42px;
  color: white;
  display: flex;
  align-items: center;
  text-align: left;
  padding: 0 15px;
  width: 40%;
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-right: none;
}

/* Contenuto della card con input e azioni */
.card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  width: 60%;
  background-color: #f8f9fa;
}

.input-container {
  display: flex;
  align-items: center;
  width: 60%;
  padding: 0 5px;
}

.input-value {
  height: 32px;
  color: var(--color-gray-ico);
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0 10px;
  border: none;
  border-radius: 6px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
  background-color: transparent;
}

.input-value:focus {
  outline: none;
  background-color: rgba(253, 140, 34, 0.05);
}

.card-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 40%;
  padding-right: 5px;
}

/* Versione tablet */
@media (max-width: 768px) {
  .voce-cassa-card {
    margin-bottom: 10px;
    height: 46px;
  }

  .nome {
    padding: 0 12px;
    font-size: 14px;
    height: 46px;
    width: 45%;
  }

  .card-content {
    padding: 0 8px;
    width: 55%;
  }

  .input-value {
    height: 36px;
    font-size: 16px;
    padding: 0 8px;
  }

  .card-actions {
    gap: 12px;
    padding-right: 5px;
  }
}

/* Versione mobile */
@media (max-width: 480px) {
  .voce-cassa-card {
    margin-bottom: 8px;
    height: 42px;
  }

  .nome {
    padding: 0 10px;
    font-size: 13px;
    height: 42px;
    width: 40%;
  }

  .card-content {
    padding: 0 6px;
    width: 60%;
  }

  .input-container {
    width: 55%;
  }

  .input-value {
    height: 32px;
    font-size: 14px;
    padding: 0 5px;
  }

  .card-actions {
    width: 45%;
    gap: 8px;
    padding-right: 2px;
  }
}

/* Versione mobile piccola */
@media (max-width: 360px) {
  .voce-cassa-card {
    height: 38px;
  }

  .nome {
    padding: 0 8px;
    font-size: 12px;
    height: 38px;
  }

  .input-value {
    height: 28px;
    font-size: 13px;
  }

  .card-actions {
    gap: 6px;
  }
}