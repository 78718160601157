.container-lista-crediti {
  background-color: var(--background-color);
  height: 100vh;
  margin-left: 13px;
  margin-top: 10px;
  display: flex;
  gap: 20px;
}

.lista-crediti {
  margin-left: 4vw;
  display: flex;
  flex-direction: column;
  height: 92%;
  margin-right: 2vw;
  width: 45%;
  border-radius: 10px;
}

.lista-credit-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  margin-bottom: 1vw;
  height: 3.5vw;
  border-radius: 12px;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  width: 100%;
  transition: all 0.3s ease;
  padding: 0 10px;
}

.lista-credit-head:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
  transform: translateY(-2px);
}

.lista-crediti input {
  width: 30vw;
  height: 3vw;
  font-size: 1.1vw;
  border: none;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.3s ease;
  color: rgba(0, 0, 0, 0.8);
}

.lista-crediti input:focus {
  outline: none;
  background-color: rgba(0, 0, 0, 0.02);
}

.btn-add-credito {
  background-color: var(--accent-color) !important;
  color: white !important;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.btn-add-credito:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  background-color: var(--accent-color-hover) !important;
}

.storico-crediti-head {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
  height: 3vw;
  width: 20vw;
  border-radius: 12px;
  font-size: 1.1vw;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.storico-crediti-head:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
}

.storico-crediti-body {
  height: 38vw;
  overflow-y: auto;
  width: 20vw;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  background-color: white;
  height: 85%;
  margin-top: 0.5vw;
  border-radius: 12px;
  padding: 0.5vw;
  transition: all 0.3s ease;
}

.storico-crediti-body:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
}

.storico-render {
  background-color: #fff;
  width: 20vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(224, 224, 224, 0.3);
  margin-bottom: 0.5vw;
  margin-top: 0.5vw;
  padding: 10px 15px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.storico-render:hover {
  background-color: rgba(0, 0, 0, 0.02);
  transform: translateY(-2px);
}

.storico-render p {
  margin-right: 1.4vw;
  font-size: 14px;
}

.lista-crediti-body {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  overflow-y: scroll;
  width: 100%;
}

.col-fm {
  cursor: pointer;
}

.table-fm {
  background-color: #fff;
}

.t-fm {
  display: block;
  height: 73.5vh;
  overflow-y: auto;
  margin-left: 0.5vw;
  border-radius: 12px;
}

@media (max-width: 700px) {
  .container-lista-crediti {
    margin-left: 9vw;
    display: flex;
    flex-direction: column;
    width: 85vh;
    gap: 10px;
  }

  .lista-crediti input {
    width: 80%;
    height: 6vw;
    margin-bottom: 1vh;
    margin-left: 2vw;
    margin-top: 0.5vw;
    font-size: 16px;
  }

  .lista-credit-head {
    height: 4vh;
    margin-top: 0.5vw;
  }

  .storico-crediti-container {
    margin-left: 4vw;
    width: 83vh;
    height: 46vh;
  }

  .storico-crediti-head {
    height: 4vh;
    margin-top: 1.4vw;
    width: 83vw;
    font-size: 100%;
  }

  .storico-crediti-body {
    width: 83vw;
  }

  .storico-render {
    width: 90%;
  }

  .storico-render p {
    margin-right: 1.4vw;
    margin-bottom: 1vw;
    font-size: 12px;
  }

  .btn-add-credito {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }

  .storico-data {
    font-size: 12px;
  }

  .storico-ora {
    font-size: 11px;
  }
}

/* Stile moderno per la tabella dei crediti */
.credits-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 20px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  background-color: white;
}

.credits-table:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
}

.credits-table th {
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
  text-align: left;
  padding: 16px 20px;
  font-size: 14px;
  letter-spacing: 0.5px;
  border-bottom: 1px solid rgba(224, 224, 224, 0.3);
}

.credits-table td {
  padding: 14px 20px;
  border-bottom: 1px solid rgba(224, 224, 224, 0.3);
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.credits-table tr:hover td {
  background-color: rgba(0, 0, 0, 0.02);
}

.credits-table tr:last-child td {
  border-bottom: none;
}

/* Stile per i valori positivi e negativi */
.positive-amount {
  color: var(--color-save-ico) !important;
  font-weight: 500 !important;
}

.negative-amount {
  color: var(--color-delete-ico) !important;
  font-weight: 500 !important;
}

/* Stile moderno per il box del totale crediti */
.total-credits-box {
  background-color: var(--secodary-color);
  color: white;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.total-credits-box .total-amount {
  font-size: 24px;
  font-weight: 600;
  margin-left: 10px;
}

.total-credits-box .total-label {
  font-size: 16px;
  opacity: 0.9;
}

/* Stile moderno per il campo di ricerca */
.search-container {
  position: relative;
  margin: 20px 0;
  width: 100%;
}

.search-input {
  width: 100%;
  padding: 12px 20px;
  padding-right: 40px;
  border: none;
  border-radius: 10px;
  background-color: #f5f5f5;
  font-size: 14px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.search-input:focus {
  outline: none;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.search-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #757575;
}

/* Stile moderno per l'intestazione "STORICO CREDITI" */
.history-header {
  background-color: var(--secodary-color);
  color: white;
  border-radius: 10px;
  padding: 16px 20px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Stile per la lista dello storico */
.history-list {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  max-height: 500px;
  overflow-y: auto;
}

.history-item {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid rgba(224, 224, 224, 0.4);
  transition: background-color 0.3s ease;
}

.history-item:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.history-item:last-child {
  border-bottom: none;
}

.history-date {
  font-size: 14px;
  color: #757575;
}

.history-time {
  font-size: 14px;
  color: #757575;
  margin-left: 10px;
}

.history-amount {
  font-size: 14px;
  font-weight: 500;
  color: #f44336;
}

/* Stile per il pulsante di eliminazione */
.delete-button {
  color: #f44336;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.delete-button:hover {
  background-color: rgba(244, 67, 54, 0.1);
}

/* Stile per le informazioni dello storico */
.storico-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.storico-data {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 4px;
}

.storico-ora {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 700px) {
  .storico-data {
    font-size: 12px;
  }

  .storico-ora {
    font-size: 11px;
  }
}
