.delete-ico-container {
  display: flex;
  flex-direction: row;
}
.delete-ico {
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  color: #ec6a5e;
  z-index: 1;
}
.delete-ico-x {
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  position: relative;
  margin-left: -12px;
  color: #ec6a5e;

  z-index: 0;
}
.delete-ico:hover {
  color: #45464a;
}

.delete-button {
  color: var(--color-delete-ico) !important;
  transition: all 0.3s ease !important;
}

.delete-button:hover {
  color: var(--color-delete-ico) !important;
  background-color: rgba(236, 106, 94, 0.1) !important;
  transform: translateY(-2px);
}
