.voice-setting {
  background-color: var(--background-color);
  width: calc(100% - 23px);
  height: 100vh;
  margin-left: 23px;
  margin-top: 10px;
  padding: 20px;
}

.voice-body {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  height: calc(100vh - 60px);
  overflow-y: auto;
  padding: 10px;
}

.voice-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 20px;
}

.voice-title {
  font-size: 24px;
  font-weight: 600;
  color: var(--primary-color);
}

.voice-description {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 5px;
}

@media (max-width: 768px) {
  .voice-setting {
    margin-left: 0;
    padding: 15px;
    width: 100%;
  }

  .voice-body {
    justify-content: center;
    padding: 5px;
  }

  .voice-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10px;
  }
}
