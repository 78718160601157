/* Design compatto per le card dei movimenti */
.movement-card {
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  padding: 0;
  border-radius: 8px;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

/* Prima riga: Nome movimento e orario */
.movement-header {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 8px 12px;
  border-bottom: 1px solid #eaeaea;
  width: 100%;
}

.movement-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.movement-name {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: var(--primary-color) !important;
  line-height: 1.2 !important;
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.movement-time {
  display: flex;
  align-items: center;
  color: #888;
}

.time-text {
  margin-left: 4px !important;
  font-size: 10px !important;
  color: #888 !important;
}

/* Seconda riga: Valore e azioni */
.movement-content {
  display: flex;
  padding: 8px 12px;
  background-color: white;
  justify-content: space-between;
  align-items: center;
}

.movement-value {
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 1.2 !important;
  margin: 0 !important;
}

.movement-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* Manteniamo il vecchio stile per compatibilità */
.movement-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

.movement-description {
  width: 60%;
  padding-right: 10px;
  cursor: pointer;
}

.description-text {
  font-weight: 500 !important;
  color: var(--primary-color) !important;
  font-size: 13px !important;
  white-space: normal;
  overflow: visible;
  line-height: 1.2 !important;
}

.movement-amount {
  width: 40%;
  text-align: right;
}

.amount-value {
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 1.2 !important;
}

/* Responsive design */
@media (max-width: 768px) {
  .movement-header {
    padding: 6px 10px;
  }

  .movement-content {
    padding: 6px 10px;
  }

  .movement-name {
    font-size: 11px !important;
    max-width: 65%;
  }

  .movement-value {
    font-size: 14px !important;
  }

  .time-text {
    font-size: 9px !important;
  }
}
