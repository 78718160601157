.home {
  display: flex;
  background-color: var(--background-color);
  height: 100vh;
  margin-left: 23px;
  padding: 10px;
}

.home-content-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 10px;
}

.data-pikers {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.data-calendar {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: none !important;
  overflow: hidden;
}

.icon-calendar {
  font-size: 20px;
  margin-left: 10px;
  color: var(--primary-color);
  transition: all 0.3s ease;
}

.icon-calendar:hover {
  color: var(--accent-color);
  transform: scale(1.05);
}

.data-pikers-date {
  height: 40px;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
}

.btn-date {
  color: var(--secodary-color);
  height: 50px;
  padding: 0 20px;
  background-color: white;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.btn-date:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.input-e-data-pikers {
  display: flex;
  justify-content: space-between;
  margin-right: -2vw;
  align-items: center;
}
.input-e-data-pikers form input {
  height: 4vw;
  width: 100%;
  font-size: 120%;
  text-align: center;
  border: 1px solid var(--glass-border);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  color: var(--primary-color);
  transition: all 0.3s ease;
}
.input-e-data-pikers form input:active,
.input-e-data-pikers form input:focus {
  border-color: var(--accent-color);
  outline: none;
  box-shadow: 0 0 0 2px rgba(253, 140, 34, 0.2);
}

.swal2-header {
  font-size: 1.3vw;
  margin-bottom: -1vw;
  margin-top: 1.5vw;
}
.spinner-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
div:where(.swal2-container) div:where(.swal2-actions) {
  z-index: 0 !important;
}
div:where(.swal2-container) {
  z-index: 100 !important;
}

/* Card styles for home page */
.summary-card {
  background: var(--glass-background);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid var(--glass-border);
  box-shadow: 0 8px 32px 0 var(--glass-shadow);
  padding: 20px;
  margin: 10px;
  transition: all 0.3s ease;
}

.summary-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 48px 0 var(--glass-shadow);
}

.summary-card.positive {
  border-left: 4px solid var(--positive-color);
}

.summary-card.negative {
  border-left: 4px solid var(--negative-color);
}

.summary-card.neutral {
  border-left: 4px solid var(--secondary-color);
}

.summary-card h3 {
  color: var(--primary-color);
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: 500;
}

.summary-card h2 {
  color: var(--primary-color);
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.summary-card .trend {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 500;
}

.summary-card .trend.up {
  color: var(--positive-color);
}

.summary-card .trend.down {
  color: var(--negative-color);
}

@keyframes new {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(0.95);
  }
  100% {
    transform: scaleX(1);
  }
}

/* mobile view */
@media (max-width: 768px) {
  .home {
    flex-direction: column;
    margin-left: 0;
    padding: 10px 5px;
    height: auto;
    overflow-y: auto;
  }

  .home-content-wrapper {
    flex-direction: column;
    width: 100%;
  }

  .btn-date {
    height: 40px;
    font-size: 14px;
    padding: 0 15px;
    width: auto;
    margin: 0;
  }

  .icon-calendar {
    font-size: 18px;
    margin-left: 5px;
  }

  .input-e-data-pikers {
    flex-direction: column;
    margin-right: 0;
    width: 100%;
  }

  .input-e-data-pikers .form-container {
    width: 100%;
    margin-bottom: 10px;
  }

  .input-e-data-pikers form input {
    height: 40px;
    font-size: 16px;
    width: 100%;
  }

  .input-e-data-pikers .data-pikers {
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: center;
  }

  .data-calendar {
    margin: 0;
    width: 100%;
  }

  .span-text {
    display: inline;
    font-size: 14px;
  }

  .summary-card {
    margin: 8px 0;
    padding: 15px;
    width: 100%;
  }

  .summary-card h3 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .summary-card h2 {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .swal2-header {
    font-size: 16px;
    margin: 10px 0;
  }

  /* Stile per il selettore di data su mobile */
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__input-container input {
    width: 100%;
    height: 40px;
    font-size: 16px;
    text-align: center;
  }

  /* Stile per il popup del datepicker su mobile */
  .react-datepicker-popper {
    width: 100%;
    max-width: 320px;
    z-index: 1000 !important;
  }

  .react-datepicker {
    width: 100%;
    font-size: 14px;
  }
}

/* Stili specifici per schermi molto piccoli (smartphone) */
@media (max-width: 480px) {
  .home {
    padding: 5px;
  }

  .home-content-wrapper {
    padding: 0;
  }

  .btn-date {
    height: 36px;
    font-size: 12px;
    padding: 0 10px;
  }

  .summary-card {
    padding: 12px;
    margin: 5px 0;
  }

  .summary-card h3 {
    font-size: 14px;
  }

  .summary-card h2 {
    font-size: 18px;
  }

  .swal2-popup {
    width: 90% !important;
    padding: 10px !important;
  }

  .swal2-input {
    height: 36px !important;
    font-size: 14px !important;
  }
}
