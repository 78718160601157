.voice-card-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  width: 280px;
  margin: 15px;
  padding: 20px;
  transition: all 0.3s ease;
}

.voice-card-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
}

.voice-card-container-add {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  width: 280px;
  height: 280px;
  margin: 15px;
  padding: 20px;
  transition: all 0.3s ease;
}

.voice-card-container-add:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
}

.text-group {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  margin-bottom: 15px;
  margin-top: 5px;
}

.ico-delete-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.input-group {
  margin-top: 10px;
}

.input-group input {
  text-align: center;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 8px;
  background-color: #f5f5f5;
  font-size: 14px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.input-group input:focus {
  outline: none;
  background-color: white;
  box-shadow: 0 0 0 2px rgba(253, 140, 34, 0.2);
}

.btn-save-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.btn-save-container button {
  border: none;
  border-radius: 8px;
  background-color: var(--accent-color);
  padding: 10px 20px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(253, 140, 34, 0.3);
}

.btn-save-container button:hover {
  background-color: rgba(253, 140, 34, 0.9);
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(253, 140, 34, 0.4);
}

@media (max-width: 768px) {
  .voice-card-container {
    width: 90%;
    max-width: 320px;
    margin: 10px auto;
  }

  .voice-card-container-add {
    width: 90%;
    max-width: 320px;
    height: auto;
    aspect-ratio: 1/1;
    margin: 10px auto;
  }
}
