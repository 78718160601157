.contact-form-container {
  margin-top: 30px;
  margin-left: 23px;
  width: calc(100% - 23px);
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 100px);
}

.contact-form-paper {
  width: 100%;
  max-width: 700px;
  padding: 40px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.contact-form-paper:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
  transform: translateY(-5px);
}

.contact-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.contact-icon {
  font-size: 48px !important;
  color: var(--accent-color);
  margin-right: 20px;
}

.contact-title {
  color: var(--primary-color);
  font-weight: 600;
  margin-bottom: 5px;
}

.contact-subtitle {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}

.contact-form-box {
  margin-top: 20px;
}

.submit-button {
  margin-top: 20px;
  min-width: 200px;
}

/* Stile per i campi di input */
.MuiOutlinedInput-root {
  border-radius: 8px;
  transition: all 0.3s ease;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--accent-color);
}

.MuiInputLabel-root.Mui-focused {
  color: var(--accent-color);
}

@media (max-width: 768px) {
  .contact-form-container {
    margin-left: 0;
    width: 100%;
    padding: 15px;
  }

  .contact-form-paper {
    padding: 20px;
  }

  .contact-icon {
    font-size: 36px !important;
    margin-right: 15px;
  }

  .contact-title {
    font-size: 24px;
  }

  .contact-subtitle {
    font-size: 12px;
  }

  .submit-button {
    min-width: 180px;
  }
}
