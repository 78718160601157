@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Inter", "Poppins", sans-serif;
}

:root {
  --primary-color: #2b3041;
  --accent-color: #fd8c22;
  --background-color: #f8f9fa;
}

body {
  background-color: var(--background-color);
  background-image: radial-gradient(at 40% 20%, rgba(74, 157, 255, 0.1) 0px, transparent 50%),
    radial-gradient(at 80% 0%, rgba(253, 140, 34, 0.1) 0px, transparent 50%),
    radial-gradient(at 0% 50%, rgba(76, 175, 80, 0.1) 0px, transparent 50%);
  background-attachment: fixed;
  min-height: 100vh;
}

.MuiDrawer-docked {
  background-color: var(--primary-color) !important;
}

.MuiDivider-root {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.div_ {
  /* background-color: white !important; */
  display: flex;
  flex-direction: column;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
  height: 99%;
  transition: all 0.3s ease;
}
* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

/* Glassmorphism effects for MUI components */
.MuiPaper-root {
  background: rgba(255, 255, 255, 0.25) !important;
  backdrop-filter: blur(10px) !important;
  -webkit-backdrop-filter: blur(10px) !important;
  border: 1px solid rgba(255, 255, 255, 0.18) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15) !important;
}

.MuiButton-root {
  border-radius: 8px !important;
  text-transform: none !important;
  font-weight: 500 !important;
  transition: all 0.3s ease !important;
}

.MuiButton-containedPrimary {
  background-color: var(--accent-color) !important;
}

.MuiButton-containedPrimary:hover {
  background-color: rgba(253, 140, 34, 0.85) !important;
  box-shadow: 0 8px 16px rgba(253, 140, 34, 0.3) !important;
}

.MuiInputBase-root {
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 8px !important;
  transition: all 0.3s ease !important;
}

.MuiInputBase-root.Mui-focused {
  border-color: var(--accent-color) !important;
  box-shadow: 0 0 0 2px rgba(253, 140, 34, 0.2) !important;
}

@media (max-width: 700px) {
  .div_ {
    max-width: -webkit-fill-available !important;
  }
}
