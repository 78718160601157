.stats-card {
  display: flex;
  flex-direction: column;
  width: 220px;
  border-radius: 12px !important;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-right: 15px;
  background-color: white !important;
}

.stats-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12) !important;
}

.stats-header {
  padding: 16px;
  background-color: var(--primary-color);
  color: white;
  text-align: center;
}

.stats-title {
  font-weight: 600 !important;
  color: white !important;
}

.stats-content {
  padding: 20px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stats-amount-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.stats-icon {
  font-size: 28px !important;
  color: var(--accent-color);
}

.stats-amount {
  font-weight: 700 !important;
  text-align: center;
}

.stats-divider {
  margin: 0 !important;
}

.stats-footer {
  padding: 12px 16px;
  background-color: rgba(var(--primary-color-rgb), 0.05);
}

.stats-total-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.stats-total-icon {
  font-size: 16px !important;
  color: var(--text-secondary);
}

.stats-total-label {
  color: var(--text-secondary);
}

.stats-total-value {
  font-weight: 600 !important;
  color: var(--text-color);
}

/* Legacy styles for backward compatibility */
.container_single_card_stats {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 200px !important ;
  height: 120px;
  min-width: 200px;
  background-color: #fff;
  /* border-radius: 5px; */
  padding: 10px;
  margin-right: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.footer_card_single_stats {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.footer_card_single_stats p {
  font-size: 12px;
  color: #2b3041;
}

.totale_card_single_stats {
  font-size: 30px;
  color: #2b3041;
  font-weight: normal;
}

.head_card_single_stats {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}
