.credit-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;
  padding: 12px 16px;
  margin-bottom: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.credit-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
}

.card-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.cognome-card {
  font-weight: 500;
  font-size: 14px;
  margin-right: 10px;
  width: 30%;
}

.name-card {
  font-size: 14px;
  width: 30%;
}

.value-card {
  font-weight: 500;
  font-size: 14px;
  width: 30%;
  text-align: right;
}

/* Stile per i valori positivi e negativi */
.positive-amount {
  color: var(--color-save-ico) !important;
  font-weight: 500 !important;
}

.negative-amount {
  color: var(--color-delete-ico) !important;
  font-weight: 500 !important;
}

@media (max-width: 700px) {
  .credit-card {
    padding: 10px 12px;
  }

  .cognome-card,
  .name-card,
  .value-card {
    font-size: 12px;
  }
}
