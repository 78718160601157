/* Design completamente nuovo per le card dei crediti */
.credit-card-container {
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  padding: 0;
  border-radius: 8px;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Prima riga: Nome e cognome */
.credit-card-name {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 8px 12px;
  border-bottom: 1px solid #eaeaea;
}

.name-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  color: var(--primary-color);
}

.name-text {
  font-weight: 600 !important;
  color: var(--primary-color) !important;
  font-size: 12px !important;
  line-height: 1.2 !important;
}

/* Seconda riga: Valori e orario */
.credit-card-values {
  display: flex;
  padding: 8px 12px;
  background-color: white;
}

.credit-column {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.column-label {
  font-size: 10px !important;
  color: #666 !important;
  margin-bottom: 1px !important;
}

.column-value {
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 1.2 !important;
}

.credit-time {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20%;
  color: #888;
}

.time-text {
  margin-left: 2px !important;
  font-size: 10px !important;
  color: #888 !important;
}

/* Responsive design */
@media (max-width: 768px) {
  .credit-card-name {
    padding: 6px 10px;
  }

  .credit-card-values {
    padding: 6px 10px;
  }

  .name-text {
    font-size: 11px !important;
  }

  .column-value {
    font-size: 14px !important;
  }

  .time-text {
    font-size: 9px !important;
  }
}
