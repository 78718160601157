.price-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
}
.price-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2%;
  width: 90%;
  height: 90%;
}
.price-head {
  padding-top: 2%;
  color: #2b3041;
  font-size: 5.5vw;
  text-align: center;
  margin-bottom: 1%;
}
.bold-price-fm {
  font-weight: bold !important;
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
}
.text-price-fm {
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
}
