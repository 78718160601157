.voci-cassa {
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  display: flex;
  flex-direction: column;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  width: 35%;
  min-width: 380px;
  height: calc(100vh - 40px);
}

.head-voci-cassa {
  width: 18vw;
  font-size: 1.5vw;
  height: 3vw;
  padding: 0.2vw;
  color: #0a0101;
  text-align: center;
  display: flex;
  align-items: center;
}

.head-voci-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  height: 4vw;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin-bottom: 1vw;
}

.totale-cassa-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.totale-card {
  height: auto;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.voci-card {
  height: auto;
  padding: 15px 15px 20px;
  border-radius: 12px;
  transition: all 0.3s ease;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border: none;
}

.totale-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.totale-header h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--primary-color);
  margin: 0;
}

.totale-info {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 10px;
}

.totale-item {
  flex: 1;
  min-width: 150px;
  padding: 10px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  transition: all 0.3s ease;
}

.totale-item:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.head-tot-cassa {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-color);
  margin-bottom: 5px;
}

.tot-cassa {
  font-size: 22px;
  font-weight: 600;
  color: var(--primary-color);
}

.separatore {
  width: 26vw;
  border-bottom: 4px solid #2b3041;
  margin-left: 1.6vw;
  border-radius: 10px;
  margin-bottom: 1vw;
}

.body-voci-cassa {
  overflow-y: auto;
  border-radius: 8px;
  flex: 1;
  padding: 0 12px;
  margin-top: 8px;
  margin-bottom: 12px;
  max-height: calc(100vh - 339px);
}

.footer-actions {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

/* Stile compatto per l'header */
.compact-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.compact-header-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: rgba(43, 48, 65, 0.05);
}

.compact-header-content {
  display: flex;
  flex-direction: column;
}

.compact-header-title {
  font-size: 12px !important;
  color: #666 !important;
  line-height: 1.2 !important;
}

.compact-header-value {
  font-size: 15px !important;
  line-height: 1.2 !important;
}

.header-with-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  margin-bottom: 12px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: transparent;
}

.header-title {
  font-size: 16px !important;
  color: var(--secodary-color) !important;
  font-weight: 600 !important;
}

.header-action-button {
  background-color: var(--accent-color) !important;
  color: white !important;
  width: 36px;
  height: 36px;
  border-radius: 50% !important;
  box-shadow: 0 2px 5px rgba(253, 140, 34, 0.3) !important;
  transition: all 0.3s ease !important;
}

.header-action-button:hover {
  background-color: rgba(253, 140, 34, 0.9) !important;
  box-shadow: 0 4px 10px rgba(253, 140, 34, 0.4) !important;
  transform: translateY(-2px);
}

/* Versione mobile migliorata */
@media (max-width: 768px) {
  .voci-cassa {
    width: 100%;
    margin: 10px 0;
    padding: 0;
    min-width: auto;
    height: auto;
  }

  .totale-card {
    padding: 15px;
    margin-bottom: 15px;
  }

  .voci-card {
    padding: 12px;
  }

  .body-voci-cassa {
    max-height: 50vh;
    padding: 0 8px;
  }

  .compact-header {
    margin-bottom: 12px;
  }

  .compact-header-icon {
    width: 36px;
    height: 36px;
  }

  .compact-header-title {
    font-size: 14px !important;
  }

  .compact-header-value {
    font-size: 18px !important;
  }

  .header-with-action {
    padding: 10px;
  }

  .header-title {
    font-size: 16px !important;
  }
}

/* Stili specifici per schermi molto piccoli (smartphone) */
@media (max-width: 480px) {
  .voci-cassa {
    margin: 5px 0;
  }

  .totale-card {
    padding: 12px;
    margin-bottom: 10px;
  }

  .voci-card {
    padding: 10px;
  }

  .compact-header-icon {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }

  .compact-header-title {
    font-size: 12px !important;
  }

  .compact-header-value {
    font-size: 16px !important;
  }

  .header-title {
    font-size: 14px !important;
  }

  .header-action-button {
    width: 32px;
    height: 32px;
  }

  .body-voci-cassa {
    max-height: 40vh;
  }
}
