.chart-card {
  display: flex;
  flex-direction: column;
  width: 380px;
  border-radius: 12px !important;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: 10px;
  background-color: white !important;
}

.chart-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12) !important;
}

.chart-header {
  padding: 16px;
  background-color: var(--primary-color);
  color: white;
}

.chart-title {
  font-weight: 600 !important;
  text-align: center;
}

.chart-divider {
  margin: 0 !important;
}

.chart-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: white;
}

.chart-footer {
  padding: 16px;
  background-color: rgba(var(--primary-color-rgb), 0.05);
  border-top: 1px solid rgba(var(--primary-color-rgb), 0.1);
}

.stat-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.stat-icon {
  color: var(--accent-color);
}

.stat-label {
  color: var(--text-secondary);
  font-weight: 500;
}

.stat-value {
  color: var(--accent-color);
  font-weight: 600 !important;
}

/* Legacy styles for backward compatibility */
.div_chart_single_stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 380px;
  padding: 10px;
  margin: 10px;
  overflow-y: auto;
  background-color: white !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
