.total-value {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  height: 4vw;
  font-size: 20px;
  font-weight: 600;
  text-wrap: nowrap;
  flex-wrap: nowrap;
  padding-top: 6px;
  transition: all 0.3s ease;
}
.head-tot {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--primary-color);
  height: 60px;
  width: 100%;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
  transition: all 0.3s ease;
}
.head-tot:hover {
  box-shadow: 0 12px 48px 0 rgba(31, 38, 135, 0.25);
  transform: translateY(-2px);
}
.add-buton-absolute {
  position: absolute;
  right: 0px;
  top: 20px;
  font-size: 100px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}
.add-buton-absolute:hover {
  color: var(--accent-color);
  transform: scale(1.1);
}

/* Stile per l'icona cliccabile */
.icon-clickable {
  transition: transform 0.3s, color 0.3s !important;
}
.icon-clickable:hover {
  transform: scale(1.1) !important;
  color: var(--accent-color) !important;
}

.icon_div {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  height: 60px;
  align-items: center;
}
.icon_div svg {
  font-size: 30px;
  color: white;
}
.div_tot {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  height: 60px;
  align-items: flex-start;
}
.tot-text {
  display: flex;
  height: 100%;
  color: #fff;
  text-align: start !important;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  text-wrap: nowrap;
  opacity: 0.9;
}

.total-value.status-positive {
  color: #4caf50 !important;
}

.total-value.status-negative {
  color: #f44336 !important;
}

@media (max-width: 700px) {
  .tot-text {
    height: 7vh;
    width: 30vw;
    font-size: 100%;
  }
  .total-value {
    height: 7vh;
    width: 30vw;
    font-size: 100%;
  }
  .head-tot {
    height: 7vh;
    width: 100%;
    font-size: 100%;
  }
}
